import Item from '../item/Item';
import './ItemList.css';

const ItemList = ({ products }) => {
  console.log('Products in ItemList:', products);

  return (
    <div className="list">
      {products.map((prod) => (
        <Item key={prod.id} {...prod} />
      ))}
    </div>
  );
};

export default ItemList;