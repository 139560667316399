import React from 'react';
import './welcome.css';


function Welcome() {
  return (
    <section className="welcome">
      
      <div className="welcome-text">
       
      </div>
    </section>
  );
}

export default Welcome;