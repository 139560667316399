import React from 'react';
import "./LightBox.css"


const Lightbox = ({ imageUrl, onClose }) => {
  return (
    <div className="lightbox" onClick={onClose}>
        
      <div className="lightbox-content">
        <img src={imageUrl} alt="Product" />
      </div>
      
         </div>
  );
};

export default Lightbox;