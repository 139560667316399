import tazaEnBandeja from './components/assets/Ofertas/Tazon con bandeja de madera.jpg';
import mateBandeja from './components/assets/Ofertas/mate con bandeja de madera.jpeg'
import comboDulceraTaza from './components/assets/Ofertas/bandeja madera más dulcera más taza y lleva cuchara de regalo.jpeg'
import bandejaPrimavera from './components/assets/Ofertas/Taza con bandeja pensamiento.jpeg'
import bandejaMateTazon from './components/assets/Ofertas/Tazon mas mate mas bandeja de madera.jpg'
import bandejaTazonCuchara from './components/assets/Ofertas/Tazon mas cuchara mas bandeja de madera.jpg'
import TazaBande1 from './components/assets/Ofertas/Taza mas bandeja1.jpg'
import TazaBande2 from './components/assets/Ofertas/taza mas bandeja2.jpg'
import TazaBande3 from './components/assets/Ofertas/taza mas bandeja3.jpg'
import TazaBande5 from './components/assets/Ofertas/taza mas bandeja5.jpg'
import TazaBande6 from './components/assets/Ofertas/taza mas bandeja6.jpg'
import TazaBande7 from './components/assets/Ofertas/taza mas bandeja7.jpeg'
import taza1 from './components/assets/Ofertas/taza1.jpeg'
import taza2 from './components/assets/Ofertas/taza2.jpeg'
import taza3 from './components/assets/Ofertas/taza3.jpeg'
import taza4 from './components/assets/Ofertas/taza4.jpeg'
import taza5 from './components/assets/Ofertas/taza5.jpeg'
import taza7 from './components/assets/Ofertas/taza7.jpeg'
import posaTorta from './components/assets/Ofertas/posaTorta.jpeg'
import taza46001 from './components/assets/Ofertas/taza46001.jpeg'
import taza46002 from './components/assets/Ofertas/taza46002.jpeg'
import taza46003 from './components/assets/Ofertas/taza46003.jpeg'
import taza46004 from './components/assets/Ofertas/taza46004.jpeg'
import taza46005 from './components/assets/Ofertas/taza46005.jpeg'
import setmate1 from './components/assets/setmate1.jpeg'
import setmate2 from './components/assets/setmate2.jpeg'
import setmate3 from './components/assets/setmate3.jpeg'
import cucharas from './components/assets/cucharas.jpeg'
import tazaVintage from './components/assets/tazavintage.jpeg'
import setBandejaMate from './components/assets/setBandejaMate.jpeg'

export const products2 = [
    
   
     
      {
        
        name: " Set primavera (taza y babdeja)",
        price: 21950,
        category: 'set',
        img: [bandejaPrimavera
        ],
        stock: 1 ,
        description: '',
        id: 9103,
        
      },
   
      {
        
        name: "Set  infantiles taza + bandeja. Varios modelos ",
        price: 22350,
        category: 'set',
        img: [TazaBande1, TazaBande2, TazaBande3, TazaBande5, TazaBande6,  TazaBande7] ,
        stock: 1 ,
        description: '',
        id: 9106,
        
      },
      {
        
        name: "Tazas infatiles varios diseños ",
        price: 9150,
        category: 'tazas',
        img: [taza1, taza2, taza3, taza4,taza5,taza7],
        stock: 1 ,
        description: '',
        id: 9107,
        
      },
      {
        
        name: "Posa torta ",
        price: 19500,
        category: 'platos',
        img: posaTorta,
        stock: 1 ,
        description: '',
        id: 9108,
        
      },
      {
        
        name: "Tazas. Varios modelos",
        price:9100,
        category: 'tazas',
        img:[taza46001, taza46002, taza46003, taza46004,taza46005,],
        stock: 1 ,
        description: '',
        id: 9109,
        
      },
      {
        
        name: "Set bandeja de madera + 1 mate",
        price: 15000,
        category: 'set',
        img: [setmate1, setmate2, setmate3],
        stock: 1 ,
        description: "",
        id: 9110
        
      },
      
      {
        
        name: "Cucharas",
        price: 3500,
        category: 'cucharitas',
        img:cucharas,
        stock: 1 ,
        description: "",
        id: 9111
        
      },
      {
        
        name: "Taza Vintage",
        price: 10290,
        category: 'tazas',
        img:tazaVintage,
        stock: 1 ,
        description: "",
        id: 9201
        
      },
      
  ];

export const getProducts = () => {
    return new Promise ((resolve)=> {
        setTimeout(() => {
            resolve (products2)
        },500)
    })
}

export const getProductById = (productId) => {
    return new Promise ((resolve) => {
        setTimeout(()=> {
            resolve(products2.find(prod => prod.id === parseInt(productId)))
        },500)
    })
}

export const getProductByCategory = (productId) => {
    return new Promise ((resolve) => {
        setTimeout(()=> {
            resolve(products2.filter(prod => prod.category === productId))
        },500)
    })

}

export const getProductsFromMock2 = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(products2);
      }, 1000);
    });
  };