import React from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import './Item.css'

const Item = ({ id, name, img, price, stock}) => {
  const mainImage = Array.isArray(img) ? img[0] : img;
  return (
    <article className="product-card">
      <header className="Header">
        <h2 className=" product-name">
          {name}
        </h2>
      </header>
      <picture>
        <img src={mainImage} alt={name} className="product-image" />
      </picture>
      <section>
        <p className="product-price">
          Precio: $ {price}
        </p>
        <p className="product-stock">
          Stock: {stock}
        </p>
      </section>
      <footer className="ItemFooter">
        <Link className="btnDetail"to= {`/item/${id}`}> Ver detalle</Link> 
        
      </footer>
    </article>
  );
};

export default Item