import { useState, useContext } from "react";
import ItemCount from "../ItemCount/ItemCount";
import { Link } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import "./ItemDetail.css"
import { Carousel } from "react-responsive-carousel"; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Lightbox from "../lightBox/LightBox";

const ItemDetail = ({id, name, img ,category,description,price, stock}) => {
  const [quantityAdded, setQuantityAdded] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Estado para controlar la imagen actual del Carousel
  const [showLightbox, setShowLightbox] = useState(false); // Estado para controlar si el Lightbox está abierto
  const { addItem } = useContext(CartContext);
 
  const handledOnAdd = (quantity) => {
      setQuantityAdded(quantity);
      const item = { id, name, img, category, price };
      addItem(item, quantity);
  }

  const handleImageClick = (index) => {
      setCurrentImageIndex(index); // Actualiza el índice de la imagen actual
      setShowLightbox(true); // Abre el Lightbox
  };
  
  const handleCloseLightbox = () => {
      setShowLightbox(false); // Cierra el Lightbox
  };

  const handleCarouselChange = (index) => {
      setCurrentImageIndex(index); // Actualiza el índice de la imagen actual cuando cambia el Carousel
  };

  return (
      <article className="containeritemdetail"> 
          <div className="bloque">
              <div className="bloque1">
                  <header className="detailname"> 
                      <h2>{name}</h2>
                  </header>
                  <Carousel 
                      showThumbs={false} 
                      dynamicHeight 
                      selectedItem={currentImageIndex} 
                      onChange={handleCarouselChange} // Maneja el cambio de imagen en el Carousel
                  >
                      {Array.isArray(img) ? (
                          img.map((image, index) => (
                              <div key={index} onClick={() => handleImageClick(index)}>
                                  <img src={image} alt={name} />
                              </div>
                          ))
                      ) : (
                          <div onClick={() => handleImageClick(0)}>
                              <img src={img} alt={name} />
                          </div>
                      )}
                  </Carousel>
              </div>
              <section className="bloque2">
                  {description && <p>Descripción: {description}</p>}
                  <p>Precio: $ {price}</p>
              </section>
          </div>
          <footer>
              {quantityAdded > 0 ? (
                  <div className="agregado">
                      <Link to='/cart' className='Option'><button>Terminar compra</button></Link>
                      <Link to='/' className='Option'><button>Seguir comprando</button></Link>
                  </div>
              ) : (
                  <ItemCount initial={1} stock={stock} onAdd={handledOnAdd}/>
              )}
          </footer>
          {showLightbox && <Lightbox imageUrl={Array.isArray(img) ? img[currentImageIndex] : img} onClose={handleCloseLightbox} />} {/* Renderiza el Lightbox si está abierto */}
      </article>
  );
}

export default ItemDetail;