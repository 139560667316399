import { useState,useEffect } from "react";
import { getProductsFromMock, products} from "../../asyncMock";
import { getProductsFromMock2, products2 } from "../../asyncMockO";

import ItemDetail from "../ItemDetail/ItemDetail";
import { useParams } from "react-router-dom";

import "./ItemDetailContainer.css"




const ItemDetailContainer = () => {
const [product, setProduct] = useState (null)
const [loanding, setLoanding] =useState (true)  
const {itemId} = useParams () 


    useEffect (() => {
        setLoanding(true)
        const allProducts = [...products, ...products2];

        getProductsFromMock().then(() => {
          const selectedProduct = allProducts.find((p) => p.id === parseInt(itemId, 10));
    
          console.log("itemId:", itemId);
          console.log("allProducts:", allProducts);
    
          if (selectedProduct) {
            setProduct(selectedProduct);
          } else {
            // Maneja el caso en el que no se encuentre el producto
            console.error("Producto no encontrado");
          }
    
          setLoanding(false);
        });
      }, [itemId]);
    
      return (
        <div className="containerdetail">
          <ItemDetail {...product} />
        </div>
      );
    };
    
    export default ItemDetailContainer;