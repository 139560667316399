import tazapajarito from './components/assets/tazapajarito.jpeg';
import tazamariposa from './components/assets/tazamariposarosa.jpeg';
import tazonturco from './components/assets/tazonojoturco2.jpeg';
import dulceritamariposa1 from './components/assets/dulceritamariposa1.jpeg';
import dulceritamariposa2 from './components/assets/dulceritamariposa2.jpeg';
import tazonrosa from './components/assets/tazonojoturco1.jpeg';
import tizanera1 from './components/assets/tizanera1.jpeg'
import tizanera2 from './components/assets/tizanera2.jpeg'
import tizanera3 from './components/assets/tizanera3.jpeg'
import bolsflor from './components/assets/bolsflor.jpeg'
import bolsfrida from './components/assets/bolsfrida.jpeg'
import bolslavanda from './components/assets/bolslavanda.jpeg'
import azucarera1 from './components/assets/azucarera1.jpeg'
import azucarera2 from './components/assets/azucarera2.jpeg'
import azucarera3 from './components/assets/azucarera3.jpeg'
import azucarera5 from './components/assets/azucarera5.jpeg'
import tazaChoco1 from './components/assets/TazaChoco1.jpeg'
import tazaChoco2 from './components/assets/tazaChoco2.jpeg'
import tazaChoco3 from './components/assets/TazaChoco3.jpeg'
import tazaChoco4 from './components/assets/tazaChoco4.jpeg'
import taza48001 from './components/assets/taza48001.jpeg'
import taza48002 from './components/assets/taza48002.jpeg'
import taza48003 from './components/assets/taza48003.jpeg'
import tazaCatalina from './components/assets/tazaCatalina.jpeg'
import setTazaCatLech from './components/assets/SetTazaCatLech.jpeg'
import bandejaGotaAzucarera from './components/assets/bandejagotaazucarera.jpeg'
import tazaKityG from './components/assets/tazaKity.jpeg'
import  tazaKityp from './components/assets/tazaKityP.jpeg'
import setBandejaTazaAzu from './components/assets/setBandejaTazaAzu.jpeg'
import setRococo from './components/assets/SetRococo.jpeg'
import platoPajaritos from './components/assets/platoparatortaPajaritos.jpeg'
import platoPajaritosP from './components/assets/platoparatortaPajaritosP.jpeg'
import platoCorazon from './components/assets/PlatoCorazon.jpeg'
import BandejaR1 from './components/assets/bandejaR1.jpeg'
import BandejaR2 from './components/assets/BandejaR2.jpeg'
import BandejaR3 from './components/assets/BandejaR3.jpeg'
import BandejaR4 from './components/assets/BandejaR4.jpeg'
import BandejaR5 from './components/assets/BandejaR5.jpeg'
import PlatoNavideñoG from './components/assets/PlatoNavideñoG.jpeg'
import setBebe1 from './components/assets/SetBebe1.jpeg'
import setBebe2 from './components/assets/setBebe2.jpeg'
import setBebe3 from './components/assets/setBebe3.jpeg'
import setAzucarera from './components/assets/setAnimalPrint.jpeg'
import bandejaVintage from './components/assets/bandejaVintage.jpeg'
import AzucareraGrande from './components/assets/AzucareraGrande.jpeg'
import setChocolateraDulcera from './components/assets/setBandejaChocolatera.jpeg'
import setTazaAzucarera from './components/assets/setTazaAzucarera.jpeg'
import setUnicornio from './components/assets/setUnicornio.jpeg'
import setZorro from './components/assets/setZorro.jpeg'
import platoMuffin from './components/assets/platoMuffin.jpeg'
import platoPino from './components/assets/platoPino.jpeg'
import setmate1 from './components/assets/setmate1.jpeg'
import setmate2 from './components/assets/setmate2.jpeg'
import setmate3 from './components/assets/setmate3.jpeg'
import cucharas from './components/assets/cucharas.jpeg'
import tazonCirco from './components/assets/tazonCirco1.jpeg'
import azucarera4 from './components/assets/azucarera4.jpeg'
import azucarera6 from './components/assets/azucarera6.jpeg'
import tazaVintage from './components/assets/tazavintage.jpeg'
import BandejaTaza from './components/assets/BandejaTaza.jpeg'
import setPensamiento from './components/assets/BandejaTazaCisne.jpeg' 
import SetRococo2 from './components/assets/SetRococo2.jpeg' 
import mateLiso1 from './components/assets/mateLiso1.jpeg' 
import mateLiso2 from './components/assets/mateLiso2.jpeg' 
import mateLiso3 from './components/assets/mateLiso3.jpeg' 
import mateDiseño1 from './components/assets/mateDiseño1.jpeg'
import mateDiseño2 from './components/assets/mateDiseño2.jpeg' 
import mateDiseño3 from './components/assets/mateDiseño3.jpeg' 
import mateDiseño4 from './components/assets/mateDiseño4.jpeg' 



export const products = [
    {
     
      name: "Tazon pajarito ",
      price: 13590,
      category: 'tazones',
      img: tazapajarito,
      stock: 1 ,
      description: 'hola',
      id: 1,
      
    },
    {
        
        name: "Tazon rosa mariposa",
        price: 13590,
        category: 'tazones',
        img: tazamariposa,
        stock: 1 ,
        description: '',
        id: 2,
        
      },
      {
        
        name: "Tazon ojo turco ",
        price: 13590,
        category: 'tazones',
        img: tazonturco,
        stock: 1 ,
        description: '',
        id: 3,
        
      },
      {
        
        name: "Dulcerita rococo ",
        price: 10499,
        category: 'azucareras',
        img: [dulceritamariposa1,
        dulceritamariposa2],//separar ambas mismo precio
        stock: 1 ,
        description: '',
        id: 4,
        
      },
      {
        
        name: "Tizanera de 3 piezas ",
        price: 13590,
        category: 'tazas',
        img: tizanera1,
        stock: 1 ,
        description: '',
        id: 5,
        
      },
      {
        
        name: "Tizanera de 3 piezas ",
        price: 13590,
        category: 'tazas',
        img:tizanera2 ,
        stock: 1 ,
        description: '',
        id: 6,
        
      },
      {
        
        name: "Tizanera de 3 piezas ",
        price: 13590,
        category: 'tazas',
        img: tizanera3 ,
        stock: 1 ,
        description: '',
        id: 7,
        
      },
      {
        
        name: "Bols de flores ",
        price: 13050,
        category: 'bols',
        img: bolsflor,
        stock: 1 ,
        description: '',
        id: 8,
        
      },
      {
        
        name: "Bols de frida ",
        price: 13050,
        category: 'bols',
        img: bolsfrida,
        stock: 1 ,
        description: '',
        id: 9,
        
      },
      {
        
        name: "Bols de lavanda ",
        price: 13050,
        category: 'bols',
        img: bolslavanda,
        stock: 1 ,
        description: '',
        id: 10,
        
      },
      {
        
        name: "Tazon flores azules ",
        price: 13590,
        category: 'tazones',
        img: tazonrosa,
        stock: 1 ,
        description: '',
        id: 11,
        
      },
      {
        
        name: "Azucareras ",
        price: 10290,
        category: 'azucareras',
        img: [azucarera2,azucarera1,azucarera3,azucarera4,azucarera5,azucarera6],
        stock: 1 ,
        description: '',
        id: 12,
        
      },
      {
        
        name: "Taza Chocolatera ",
        price: 13290,
        category: 'tazas',
        img: [tazaChoco1,tazaChoco2,tazaChoco3, tazaChoco4],
        stock: 1 ,
        description: '',
        id: 13,
        
      },
      {
        
        name: "Tazas. Varios modelos ",
        price: 9150,
        category: 'tazas',
        img: [taza48001,taza48002,taza48003],
        stock: 1 ,
        description: '',
        id: 14,
        
      },
     
     
      
      {
        
        name: "Taza Kity grande ",
        price: 12249,
        category: 'tazas',
        img: tazaKityG,
        stock: 1 ,
        description: '',
        id: 15,
        
      },
      {
        
        name: "Taza Kity pequeña ",
        price: 9150,
        category: 'tazas',
        img: tazaKityp,
        stock: 1 ,
        description: '',
        id: 16,
        
      },
      {
        
        name: "Set vintage bandeja + taza y azucarera ",
        price: 20540,
        category: 'set',
        img: setBandejaTazaAzu,
        stock: 1 ,
        description: "Opcional con bandeja 25.000$",
        id: 17,
        
      },
   
      {
        
        name: "Plato para torta ",
        price: 15330,
        category: 'platos',
        img: platoPajaritos,
        stock: 1 ,
        description: "",
        id: 18,
        
      },
      {
        
        name: "Plato pequeño ",
        price: 10050,
        category: 'platos',
        img: platoPajaritosP,
        stock: 1 ,
        description: "",
        id: 19,
        
      },
      {
        
        name: "Plato corazon ",
        price: 14700,
        category: 'platos',
        img: platoCorazon,
        stock: 1 ,
        description: "",
        id: 20,
        
      },
     
      {
        
        name: "Plato Grande navideño ",
        price: 16800,
        category: 'platos',
        img: PlatoNavideñoG,
        stock: 1 ,
        description: "",
        id: 21,
        
      },
      {
        
        name: "Plato  navideño  Muffin",
        price: 14250,
        category: 'platos',
        img:platoMuffin ,
        stock: 1 ,
        description: "",
        id: 22,
        
      },
      {
        
        name: "Plato navideño  Pino",
        price: 6500,
        category: 'platos',
        img: platoPino,
        stock: 1 ,
        description: "",
        id: 23,
        
      },
      {
        
        name: "Set Peter Rabbit ",
        price: 17450,
        category: 'set',
        img: [setBebe1,setBebe2,setBebe3],
        stock: 1 ,
        description: "",
        id: 24,
        
      },
        {
        
        name: "Set animalitos del bosque",
        price: 17359,
        category: 'set',
        img: setZorro,
        stock: 1 ,
        description: "",
        id: 25,
        
      },
  
      {
        
        name: "Bandeja vintage casa navideña",
        price: 13020,
        category: 'bandejas',
        img: bandejaVintage,
        stock: 1 ,
        description: "",
        id: 26,
        
      },
      {
        
        name: "Azucarera Margarita",
        price: 12950,
        category: 'azucareras',
        img: AzucareraGrande, 
        stock: 1 ,
        description: "",
        id: 27,
        
      },
    
      
      {
        
        name: "Set Unicornio",
        price: 22350,
        category: 'set',
        img: setUnicornio,
        stock: 1 ,
        description: "",
        id: 28
        
      },
      
      {
        
        name: "Set bandeja de madera + 1 mate",
        price: 15000,
        category: 'set',
        img: [setmate1, setmate2, setmate3],
        stock: 1 ,
        description: "",
        id: 29
        
      },
      {
        
        name: "Cucharas",
        price: 3500,
        category: 'cucharitas',
        img:cucharas,
        stock: 1 ,
        description: "",
        id: 30
        
      },
      {
        
        name: "Tazon Circo",
        price: 13350,
        category: 'tazones',
        img:tazonCirco,
        stock: 1 ,
        description: "",
        id: 31
        
      },
      {
        
        name: "Azucarera",
        price: 10290,
        category: 'azucareras',
        img:azucarera4,
        stock: 1 ,
        description: "",
        id: 32
        
      },
      {
        
        name: "Taza Vintage",
        price: 10290,
        category: 'tazas',
        img:tazaVintage,
        stock: 1 ,
        description: "",
        id: 33
        
      },
      {
        
        name: "Set pensamiento",
        price: 26400,
        category: 'set',
        img:BandejaTaza,
        stock: 1 ,
        description: "",
        id: 34
        
      },
      {
        
        name: "Set cisne",
        price: 19650,
        category: 'set',
        img:setPensamiento,
        stock: 1 ,
        description: "",
        id: 35
        
      },
      {
        
        name: "Set rococo",
        price:  12990,
        category: 'set',
        img:SetRococo2,
        stock: 1 ,
        description: "",
        id: 36
        
      },
      {
        
        name: "Mates lisos",
        price: 5000,
        category: 'mates',
        img: [mateLiso1 ,mateLiso2,mateLiso3],
        stock: 1 ,
        description: "",
        id: 37
        
      },
      {
        
        name: "Mates diseños varios",
        price: 7590,
        category: 'mates',
        img: [mateDiseño1 ,mateDiseño2,mateDiseño3,mateDiseño4],
        stock: 1 ,
        description: "",
        id: 38
        
      },
   
  ];

export const getProducts = () => {
    return new Promise ((resolve)=> {
        setTimeout(() => {
            resolve (products)
        },500)
    })
}

export const getProductById = (productId) => {
    return new Promise ((resolve) => {
        setTimeout(()=> {
            resolve(products.find(prod => prod.id === parseInt(productId)))
        },500)
    })
}

export const getProductByCategory = async () => {
  // Simular una llamada a una API o base de datos
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(products);
    }, 500);
  });
};

export const getProductsFromMock = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(products);
      }, 1000);
    });
  };

